<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-btn icon @click="$router.go(-1)" v-if="!!this.$route.params.org">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.users")
        }}</v-toolbar-title>
        <v-spacer />
        <!-- <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn class="d-none d-md-flex" text x-small :disabled="!selected" @click="clearSelectedUser()">
          <v-icon>mdi-circle-off-outline</v-icon>
          <span class="text--secondary">{{ $t("general.deselect") }}</span>
        </v-btn> -->
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          :disabled="isReadOnly"
          text
          x-small
          color="green"
          @click="$router.push({ name: 'user', params: { id: 'new' } })"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="editRoles()"
        >
          <v-icon>mdi-key</v-icon>
          <span>{{ $t("user.roles") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="editUser()"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="red"
          v-if="$hasRole('Administrator')"
          :disabled="!selected"
          @click="confirmDeleteUser()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{$t("general.delete")}}</span>
        </v-btn>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-data-table
          dense
          :headers="headers"
          :items="getUsers"
          item-key="id"
          :search="search"
          show-select
          single-select
          @item-selected="selectionChanged"
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="blue"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <!-- <template #header.id="{ header }">{{ $t(header.text) }}</template> -->
          <template #header.username="{ header }">
            <span class="ml-9">{{ header.text }}</span>
          </template>
          <!-- <template #header.name="{ header }">{{ $t(header.text) }}</template>
          <template #header.email="{ header }">{{ $t(header.text) }}</template>
          <template #header.roles="{ header }">{{ $t(header.text) }}</template> -->

          <template #item.username="{ item }">
            <v-avatar size="32" class="my-1 mr-1">
              <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
              <v-icon v-else large color="primary">{{
                userInitials(item.username)
              }}</v-icon>
            </v-avatar>
            <span
              :class="
                !item.enabled ? 'text-decoration-line-through red--text' : ''
              "
              >{{ item.username }}</span
            >
            <v-icon v-if="!item.enabled" color="red" small
              >mdi-account-cancel</v-icon
            >
          </template>
          <template #item.roles="{ item }">
            <v-chip
              v-for="role in item.roles"
              :key="role"
              :color="
                role === 'Administrator'
                  ? 'red'
                  : role === 'User'
                  ? 'blue'
                  : 'deep-purple'
              "
              dark
              class="ma-1 px-1"
              small
              label
            >
              {{ role }}
            </v-chip>
          </template>
          <template #item.created="{ item }">
            <span>{{ getFromNow(item.created) }}</span>
          </template>
          <!-- <template #item.actions="{ item }">
          <v-btn text x-small color="primary" @click="`/orgs/${item.id}`">
            <v-icon small>mdi-file-edit-outline</v-icon>
            <span>{{$t("general.edit")}}</span>
          </v-btn>
          <v-btn text x-small color="red" @click="deleteDialog = true">
            <v-icon small>mdi-delete</v-icon>
            <span>{{$t("general.delete")}}</span>
          </v-btn>
        </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "Users",
  data() {
    return {
      org: null,
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 50 },
        { text: this.$t("user.username"), value: "username" },
        { text: this.$t("user.firstName"), value: "firstName" },
        { text: this.$t("user.lastName"), value: "lastName" },
        { text: this.$t("user.email"), value: "email" },
        { text: this.$t("general.created"), value: "created", width: 100 },
        { text: this.$t("user.roles"), value: "roles", align: "right" },
        // { text: "Actions", value: "actions", align: "right", width: 250 }
      ],
    };
  },
  computed: {
    ...mapGetters("users", ["getUsers"]),
    ...mapGetters("orgs", ["getOrgById"]),
    ...mapState({
      selected(state) {
        return state.users.selectedUser;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
    orgIdParam() {
      return parseInt(this.$route.params.org);
    },

  },
  mounted() {
    this.loading = true;
    if (this.$route.params.org) {
      this.loadUsers({ orgId: this.orgIdParam }).then(() => {
        this.loading = false;
        this.org = this.getOrgById(this.orgIdParam);
        if(!this.org) {
          this.loadOrgs().then(() => {
            this.org = this.getOrgById(this.orgIdParam);
            this.refreshBreadcrumb();
          });
        } else {
          this.refreshBreadcrumb();
        }
      });
    }
  },
  methods: {
    ...mapActions("users", [
      "loadUsers",
      "deleteUser",
      "setSelectedUser",
      "clearSelectedUser",
    ]),
    ...mapActions("orgs", ["loadOrgs"]),
    ...mapActions("ui", ["showDialog", "closeDialog", "setBreadcrumbs"]),

    refreshBreadcrumb() {
      const updatedBreadcrumbs = this.$route.meta.breadcrumb.map((item) => {
        if (item.dynamic && this.org) {
          return { ...item, name: this.org.acronym || this.org.name };
        }
        return item;
      });
      this.setBreadcrumbs(updatedBreadcrumbs);
    },
    selectionChanged(event) {
      if (event.value) {
        this.setSelectedUser(event.item);
      } else {
        this.clearSelectedUser();
      }
    },
    editUser() {
      if (this.selected) {
        this.$router.push({
          name: "user",
          params: { id: this.selected.id },
        });
      }
    },
    editRoles() {
      if (this.selected) {
        this.$router.push({
          name: "user.roles",
          params: { id: this.selected.id },
        });
      }
    },
    confirmDeleteUser() {
      if (this.selected) {
        // this.deleteDialog = true;
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${this.selected.name} user?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteUser(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
    userInitials(name) {
      if (name && name.length > 0) {
        return `mdi-alpha-${name.toLowerCase()[0]}-circle-outline`;
      }
      return "mdi-account-circle";
    },
    getFromNow(d) {
      return moment(d).fromNow(true);
    },
  },
};
</script>
